<template>
  <q-card style="min-width: 600px" class="row justify-center">
    <q-toolbar class="bg-primary text-white">
      <q-toolbar-title>Aprovar Exclusão</q-toolbar-title>
      <q-btn flat round dense icon="mdi-close" @click="$emit('close-popup')" />
    </q-toolbar>
    <div class="col-10 text-center q-mt-md q-mb-xl text-h5">Confirme suas credenciais para aprovar a exclusão:</div>

    <q-form @submit="validate" class="col-10 q-pa-md q-mb-md">
      <q-input
        outlined
        bg-color="white"
        type="email"
        v-model="form.username"
        :placeholder="$t('enter_the_email')"
        clearable
        class="rounded"
        clear-icon="close"
        label="Email"
        @keyup.enter="submit"
        lazy-rules
        :rules="[isRequired, isEmail]"
      >
        <template v-slot:prepend>
          <q-icon name="mdi-email-outline" color="primary" />
        </template>
      </q-input>
      <q-input
        clearable
        bg-color="white"
        clear-icon="close"
        outlined
        class="rounded"
        :placeholder="$t('enter_the_pass')"
        v-model="form.password"
        ref="password"
        :label="$t('password')"
        :type="showPassword ? 'text' : 'password'"
        @keyup.enter="submit"
        lazy-rules
        :rules="[isRequired]"
      >
        <template v-slot:prepend>
          <q-icon name="mdi-lock-open-outline" color="primary" />
        </template>
        <template v-slot:append>
          <q-btn
            flat
            round
            color="primary"
            no-caps
            :icon="showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
            @click="showPassword = !showPassword"
          />
        </template>
      </q-input>

      <q-btn color="positive" type="submit" unelevated>Confirmar</q-btn>
    </q-form>
  </q-card>
</template>

<script>
import UserService from '@/services/UserService';
import AuthService from '@/services/AuthService';

export default {
  name: 'AffiliateAccountExclusionApprovalModal',
  mixins: [UserService, AuthService],
  props: {
    affiliateId: {
      require: true,
      type: [String, Number]
    }
  },
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      showPassword: false
    };
  },
  methods: {
    async validate() {
      var wasValidated = await this.login(this.form);

      try {
        this.onLoading(true);
        if (wasValidated) {
          const { status } = await this.approveAffiliateAccountExclusion(this.affiliateId);
          if (status == 200) {
            this.successNotify('Exclusão aprovada.');
            this.$emit('close-popup');
          }
        }
      } catch (error) {
        if (error.response.status == 400) {
            this.errorNotify(error.response.data);
        } else {
            this.errorNotify('Não foi possível realizar a solicitação de exclusão de conta. Tente novamente mais tarde.');
        }
      } finally {
        this.onLoading(false);
      }
    },

    async login(form) {
      this.onLoading(true);
      var wasValidated = false;
      try {
        if (!form.username) throw this.$t('validator.required.email');
        if (!form.password) throw this.$t('validator.required.password');
        const { data, status } = await this.authLogin(this.form);
        if (status === 200 && data.login.user.user_id == this.user.user_id) {
          wasValidated = true;
        } else {
          this.errorNotify('Não foi possível confirmar suas credenciais. Tente novamente.');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify('Não foi possível confirmar suas credenciais. Tente novamente.');
      } finally {
        this.onLoading(false);
      }

      return wasValidated;
    }
  }
};
</script>

<style></style>
