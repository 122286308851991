// src/services/BankService.js
const serviceApp = process.env.VUE_APP_AFFILIATE_SERVICE || 'AFFILIATE';

export default {
  methods: {
    async getBanksList() {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/banks`);
    },

    async getBankByCode(code) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/banks/${code}`);
    }
  }
};