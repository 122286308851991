<template>
  <q-card flat>
    <q-card-section>
      <div class="text-h6">Meus dados</div>
    </q-card-section>
    <q-form @submit="havePaymentData ? onEdit() : onCreate()">
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-3">
          <div class="col-3">
            <span class="text-weight-medium q-my-none">Doc*</span>
            <div class="q-gutter-lg">
              <q-radio v-model="affiliateData.document_type" val="CPF" label="CPF" />
              <q-radio v-model="affiliateData.document_type" val="CNPJ" label="CNPJ" />
              <q-radio v-model="affiliateData.document_type" val="INTER" label="VAT/NIF/TIN" />
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="q-pt-none row q-col-gutter-md">
            <div class="col-6" v-if="affiliateData.document_type === 'CPF'">
              <q-input
                ref="inputCpf"
                :loading="loadingState"
                outlined
                mask="###.###.###-##"
                unmasked-value
                label="CPF*"
                v-model="affiliateData.document_number"
                type="text"
                lazy-rules
                :rules="[isRequired, isCPF]"
                @blur="getCpf(affiliateData.document_number)"
              >
                <template v-slot:append>
                  <q-btn
                    dense
                    tabindex="-1"
                    color="primary"
                    unelevated
                    icon="mdi-magnify"
                    @click="$refs.inputCpf.validate(), !$refs.inputCpf.hasError ? $refs.inputEmail.focus() : null"
                  />
                </template>
              </q-input>
            </div>
            <div class="col-6" v-if="affiliateData.document_type === 'CNPJ'">
              <q-input
                ref="inputCnpj"
                :loading="loadingState"
                outlined
                lazy-rules
                :rules="[isRequired, isCNPJ]"
                mask="##.###.###/####-##"
                unmasked-value
                label="CNPJ*"
                v-model="affiliateData.document_number"
                type="text"
                @blur="getCnpj(affiliateData.document_number)"
              >
                <template v-slot:append>
                  <q-btn
                    dense
                    tabindex="-1"
                    color="primary"
                    unelevated
                    icon="mdi-magnify"
                    @click="$refs.inputCnpj.validate(), !$refs.inputCnpj.hasError ? $refs.inputEmail.focus() : null"
                  />
                </template>
              </q-input>
            </div>
            <div class="col-6" v-if="affiliateData.document_type === 'INTER'">
              <q-input
                outlined
                lazy-rules
                :rules="[isRequired]"
                maxlength="50"
                label="VAT/NIF/TIN*"
                v-model="affiliateData.document_number"
                type="text"
                input-style="text-transform:uppercase"
              />
            </div>
            <div class="col-6">
              <q-input
                :readonly="affiliateData.document_type !== 'INTER' ? inputReadOnly : false"
                outlined
                label="Beneficiário"
                v-model="affiliateData.business_name"
                type="text"
              />
            </div>
            <div class="col-6">
              <q-input ref="inputEmail" outlined label="Email" v-model="affiliateData.email" type="email" />
            </div>
            <div class="col-6">
              <q-input
                outlined
                label="Telefone"
                v-model="affiliateData.phone_number"
                mask="+## ## #####-####"
                hint="Ex. +55 21 99999-9999"
              />
            </div>
          </div>
        </div>
      </q-card-section>
      <q-separator spaced />
      <q-card-section>
        <div class="text-h6">Informações Bancárias</div>
      </q-card-section>
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-6">
          <q-select
            v-model="affiliateData.bank_country"
            :options="accountBankOptions"
            label="País do Banco"
            outlined
            use-input
            input-debounce="0"
            @filter="filterCountry"
            clearable
            clear-icon="mdi-close"
          />
        </div>
        <div class="col-6">
          <q-select
            outlined
            v-model="affiliateData.bank_account_type"
            :options="accountTypeOptions"
            :rules="affiliateData.bank_country === 'Brasil' ? [isRequired] : null"
            label="Tipo da conta"
          />
        </div>
      </q-card-section>
      <q-separator spaced />
      <q-card-section class="q-pt-md row q-col-gutter-md" v-if="affiliateData.bank_country === 'Brasil'">
        <div class="col-md-3">
          <q-select
          outlined
          :options="filteredBankList"
          option-label="name"
          v-model="selectedBank"
          :rules="[isRequired]"
          label="Banco"
          @filter="filterBank"
          use-input
          input-debounce="0"
          :loading="loadingBanks"
          map-options
          emit-value
          >
          </q-select>
        </div>
        <div class="col-md-3">
          <q-input
            outlined
            v-model="bankData.code"
            :rules="[isRequired]"
            type="text"
            readonly
            label="Banco Código"
            style="margin-bottom: 20px"
          />
        </div>
        <div class="col-3">
          <q-input outlined label="Agência" v-model="affiliateData.bank_agency" :rules="[isRequired]" type="text" />
        </div>
        <div class="col-2">
          <q-input
            outlined
            label="Conta"
            v-model="affiliateData.bank_account_number"
            :rules="[isRequired]"
            type="text"
          />
        </div>
        <div class="col-1">
          <q-input
            outlined
            label="Digito"
            v-model="affiliateData.bank_account_number_digit"
            :rules="[isRequired]"
            type="text"
          />
        </div>
        <div class="col-6">
          <q-select
            outlined
            :options="pixTypeOptions"
            option-value="name"
            option-label="name"
            v-model="selectedPixType"
            @input="(val) => (affiliateData.pix_type = val.name)"
            @clear="() => (selectedPixType = affiliateData.pix_type = affiliateData.pix_key = null)"
            clearable
            label="Tipo Pix"
            use-input
          />
        </div>
        <div class="col-6">
          <q-input
            :disable="selectedPixType == null"
            outlined
            v-model="affiliateData.pix_key"
            :mask="selectedPixType?.mask"
            :rules="selectedPixType?.rules"
            label="Chave Pix"
            hint="A chave PIX deve ser referente à conta bancária cadastrada."
          />
        </div>
      </q-card-section>

      <q-card-section class="q-pt-md row q-col-gutter-md" v-else>
        <!-- field 1 -->
        <div class="col-12">
          <q-input outlined label="Endereço" v-model="affiliateData.bank_address" type="text" />
        </div>
        <div :class="accountInternationOption1.type.value === 'other' ? 'col-4' : 'col-6'">
          <q-select
            outlined
            label="Código Internacional Bancário"
            v-model="accountInternationOption1Type"
            :options="accountInternationalOptions"
            :rules="[isRequired]"
          />
        </div>
        <div
          :class="accountInternationOption1.type.value === 'other' ? 'col-4' : 'col-6'"
          v-if="accountInternationOption1.type.value === 'other'"
        >
          <q-input outlined label="Campo" v-model="accountInternationOption1.param" type="text" :rules="[isRequired]" />
        </div>
        <div :class="accountInternationOption1.type.value === 'other' ? 'col-4' : 'col-6'">
          <q-input outlined label="Valor" v-model="accountInternationOption1.value" type="text" />
        </div>
        <!-- field 2 -->
        <div :class="accountInternationOption2.type.value === 'other' ? 'col-4' : 'col-6'">
          <q-select
            label="Código Internacional Bancário"
            outlined
            v-model="accountInternationOption2Type"
            :options="accountInternationalOptions"
          />
        </div>
        <div
          :class="accountInternationOption2.type.value === 'other' ? 'col-4' : 'col-6'"
          v-if="accountInternationOption2.type.value === 'other'"
        >
          <q-input outlined label="Campo" v-model="accountInternationOption2.param" type="text" />
        </div>
        <div :class="accountInternationOption2.type.value === 'other' ? 'col-4' : 'col-6'">
          <q-input outlined label="Valor" v-model="accountInternationOption2.value" type="text" />
        </div>
        <!-- field 3 -->
        <div :class="accountInternationOption3.type.value === 'other' ? 'col-4' : 'col-6'">
          <q-select
            label="Código Internacional Bancário"
            outlined
            v-model="accountInternationOption3Type"
            :options="accountInternationalOptions"
          />
        </div>
        <div
          :class="accountInternationOption3.type.value === 'other' ? 'col-4' : 'col-6'"
          v-if="accountInternationOption3.type.value === 'other'"
        >
          <q-input outlined label="Campo" v-model="accountInternationOption3.param" type="text" />
        </div>
        <div :class="accountInternationOption3.type.value === 'other' ? 'col-4' : 'col-6'">
          <q-input outlined label="Valor" v-model="accountInternationOption3.value" type="text" />
        </div>
      </q-card-section>
      <q-card-actions vertical align="right">
        <q-btn
          class="q-px-md"
          color="positive"
          no-caps
          unelevated
          type="submit"
          :label="havePaymentData ? 'Salvar Alterações' : 'Criar informações de pagamento'"
          :loading="loading"
        />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script>
import affiliateMixin from '../../../mixins/AffiliateMixin';
import affiliateService from '../../../services/AffiliateService';
import UserService from '../../../services/UserService';
import BankService from '../../../services/BankService'; // Importe o BankService
import countries from '@/assets/json/countries.json';

export default {
  name: 'AffiliateContentPayinfo',
  mixins: [affiliateMixin, affiliateService, UserService, BankService], // Adicione BankService aos mixins
  props: {
    id: {
      type: [String, Number],
      require: true
    }
  },

  // Os watches permanecem os mesmos
  watch: {
    selectedBank(newVal) {
      if (newVal) {
        this.bankData.code = newVal.code;
        this.bankData.name = newVal.name;
      } else {
        this.bankData.code = null;
        this.bankData.name = null;
      }
    },
    accountInternationOption1Type(val) {
      this.accountInternationOption1.type = val;
    },
    accountInternationOption2Type(val) {
      this.accountInternationOption2.type = val;
    },
    accountInternationOption3Type(val) {
      this.accountInternationOption3.type = val;
    }
  },

  // Os dados permanecem os mesmos
  data() {
    return {
      havePaymentData: true,
      brazilianBankList: [],
      loadingBanks: false,
      selectedPixType: null,
      accountInternationOption1Type: null,
      accountInternationOption2Type: null,
      accountInternationOption3Type: null,
      pixTypeOptions: [
        { name: 'Email', mask: '', rules: [this.isRequired, this.isEmail] },
        { name: 'Celular', mask: '## #####-####', rules: [this.isRequired] },
        { name: 'CPF', mask: '###.###.###-##', rules: [this.isRequired, this.isCPF] },
        { name: 'CNPJ', mask: '##.###.###/####-##', rules: [this.isRequired, this.isCNPJ] }
      ],
      accountTypeOptions: ['Conta Corrente', 'Conta Poupança'],
      accountBankOptions: countries.map((country) => country.name),
      accountInternationalOptions: [
        { label: 'ABA', value: 'aba' },
        { label: 'IBAN', value: 'iban' },
        { label: 'SWIFT', value: 'swift' },
        { label: 'BIC', value: 'bic' },
        { label: 'Routing number', value: 'routing_number' },
        { label: 'Outros', value: 'other' }
      ],
      accountInternationOption1: {
        type: { label: null, value: null },
        value: null
      },
      accountInternationOption2: {
        type: { label: null, value: null },
        value: null
      },
      accountInternationOption3: {
        type: { label: null, value: null },
        value: null
      },
      paymentId: null,
      affiliateData: {
        phone_number: '',
        document_number: '',
        business_name: '',
        email: '',
        document_type: '',
        bank_account_number: '',
        bank_account_number_digit: '',
        bank_account_type: '',
        bank_country: '',
        bank_address: '',
        bank_name: '',
        bank_agency: '',
        pix_key: '',
        pix_type: '',
        other_type: '',
        other_value: '',
        aba: '',
        bic: '',
        iban: '',
        swift: '',
        routing_number: ''
      },
      selectedBank: null,
      bankData: {
        name: null,
        code: null
      },
      loadingState: false,
      inputReadOnly: true,
      loading: false
    };
  },

  methods: {
      onBankSelected(selectedCode) {
      // Encontra o banco selecionado
      const selectedBank = this.brazilianBankList.find(bank => bank.code === selectedCode);
      
      if (selectedBank) {
        this.bankData.code = selectedBank.code;
        this.bankData.name = selectedBank.name;
      } else {
        this.bankData.code = null;
        this.bankData.name = null;
      }
    },
    
    async filterBank(val, update) {
  // Cancela a busca anterior se ainda estiver pendente
  if (this.filterTimeout) {
    clearTimeout(this.filterTimeout);
  }

  // Se não há valor, mostra lista completa
  if (!val) {
    update(async () => {
      this.loadingBanks = true;
      try {
        const response = await this.getBanksList('');
        this.brazilianBankList = response.data?.data || response.data || [];
      } catch (error) {
        console.error('Error loading banks:', error);
        this.brazilianBankList = [];
      } finally {
        this.loadingBanks = false;
      }
    });
    return;
  }

  // Filtro local imediato
  update(() => {
    const needle = val.toLowerCase();
    const filtered = this.brazilianBankList.filter(
      bank => bank.name.toLowerCase().includes(needle) || 
             bank.code.toString().includes(needle)
    );
    
    // Se encontrou resultados localmente, usa eles
    if (filtered.length > 0) {
      this.filteredBankList = filtered;
    }
  });

  // Busca na API após delay
  this.filterTimeout = setTimeout(async () => {
    try {
      this.loadingBanks = true;
      const response = await this.getBanksList(val);
      const apiResults = response.data?.data || response.data || [];
      
      // Combina resultados locais com API, removendo duplicatas
      const combinedResults = [...new Map(
        [...apiResults, ...this.brazilianBankList]
          .map(bank => [bank.code, bank])
      ).values()];
      
      // Filtra novamente para garantir relevância
      const needle = val.toLowerCase();
      this.filteredBankList = combinedResults.filter(
        bank => bank.name.toLowerCase().includes(needle) || 
               bank.code.toString().includes(needle)
      );
      
      update(() => {});
    } catch (error) {
      console.error('Error searching banks:', error);
    } finally {
      this.loadingBanks = false;
    }
  }, 500);
},

    async getAffiliateById(id) {
      this.onLoading(true);
      try {
        // Primeiro carrega a lista de bancos
        await this.filterBank('', (fn) => fn());
        
        const { data, status } = await this.getAffiliateDataPayment(id);
        
        if (status === 200 && data.length > 0) {
          this.havePaymentData = true;
          this.affiliateData = data[0];
          this.paymentId = this.affiliateData.id;

          // Configura o banco selecionado
          if (this.affiliateData.bank_name) {
            const [bankCode, bankName] = this.affiliateData.bank_name.split(/\s*-\s*/);
            this.selectedBank = this.brazilianBankList.find(b => b.code.toString() === bankCode?.trim());
            
            if (this.selectedBank) {
              this.bankData.code = this.selectedBank.code;
              this.bankData.name = this.selectedBank.name;
            } else if (bankCode) {
              // Se não encontrou na lista, cria um objeto temporário
              this.selectedBank = {
                code: bankCode.trim(),
                name: bankName?.trim() || bankCode.trim()
              };
              this.bankData.code = bankCode.trim();
              this.bankData.name = bankName?.trim() || bankCode.trim();
            }
          }

          // Restante da configuração...
          this.selectedPixType = this.pixTypeOptions.find((val) => val.name == this.affiliateData.pix_type);
          
          if (this.affiliateData.bank_country !== 'Brasil') {
            await this.setupOthersBankField();
          }
        } else {
          this.havePaymentData = false;
        }
      } catch (error) {
        console.error('Error loading affiliate data:', error);
        this.errorNotify('Erro ao carregar dados do afiliado');
      } finally {
        this.onLoading(false);
      }
    },

    // Os demais métodos permanecem exatamente iguais
    async getCnpj(cnpj) {
      this.inputReadOnly = true;
      this.$refs.inputCnpj.validate();
      if (!this.$refs.inputCnpj.hasError) {
        this.loadingState = true;
        try {
          const { data, status } = await this.getCnpjData(cnpj);
          if (status === 200) {
            this.affiliateData.business_name = data.razao_social;
          }
        } catch (error) {
          this.errorNotify(error.response.data.error);
        } finally {
          this.loadingState = false;
        }
      }
    },

    async getCpf(cpf) {
      this.inputReadOnly = true;
      this.$refs.inputCpf.validate();
      if (!this.$refs.inputCpf.hasError) {
        this.loadingState = true;
        try {
          const { data, status } = await this.getCpfData(cpf);
          if (status === 200) {
            this.affiliateData.business_name = data.razao_social;
          }
        } catch (error) {
          this.errorNotify(error.response.data.error);
        } finally {
          this.loadingState = false;
        }
      }
    },

    bankEdit(selectedCode) {
      // Encontra o banco selecionado na lista
      const selectedBank = this.brazilianBankList.find(bank => bank.code === selectedCode);
      
      if (selectedBank) {
        this.bankData.code = selectedBank.code;
        this.bankData.name = selectedBank.name;
      } else {
        this.bankData.code = null;
        this.bankData.name = null;
      }
    },

    filterCountry(val, update) {
      if (val === '') {
        update(() => {
          this.accountBankOptions = countries.map((country) => country.name);
        });
        return;
      }
      update(() => {
        const needle = val.toLowerCase();
        this.accountBankOptions = countries
          .map((country) => country.name)
          .filter((v) => v.toLowerCase().indexOf(needle) > -1);
      });
    },

    async setOthersBankConfirm() {
      let type = null;
      this.clearOthersBankConfirm();

      if (this.accountInternationOption1.type.value === 'other') {
        this.affiliateData.other_type = this.accountInternationOption1.param;
        this.affiliateData.other_value = this.accountInternationOption1.value;
      } else {
        type = this.accountInternationOption1.type.value;
        this.affiliateData[type] = this.accountInternationOption1.value;
      }

      if (this.accountInternationOption2.type.value === 'other') {
        this.affiliateData.other_type = this.accountInternationOption2.param;
        this.affiliateData.other_value = this.accountInternationOption2.value;
      } else if (this.accountInternationOption2.type.value != null) {
        type = this.accountInternationOption2.type.value;
        this.affiliateData[type] = this.accountInternationOption2.value;
      }

      if (this.accountInternationOption3.type.value === 'other') {
        this.affiliateData.other_type = this.accountInternationOption3.param;
        this.affiliateData.other_value = this.accountInternationOption3.value;
      } else if (this.accountInternationOption3.type.value != null) {
        type = this.accountInternationOption3.type.value;
        this.affiliateData[type] = this.accountInternationOption3.value;
      }
    },

    async setupOthersBankField() {
      let otherBankValue = [];
      this.accountInternationalOptions.forEach((element) => {
        if (this.affiliateData[element.value])
          otherBankValue.push({
            [element.value]: this.affiliateData[element.value]
          });
      });
      if (this.affiliateData.other_type)
        otherBankValue.push({
          [this.affiliateData.other_type]: this.affiliateData.other_value,
          type: 'other'
        });

      if (otherBankValue[0] && !otherBankValue[0].type) {
        this.accountInternationOption1.type = this.accountInternationalOptions.find(
          (item) => item.value === Object.keys(otherBankValue[0])[0]
        );
        this.accountInternationOption1Type = this.accountInternationOption1.type;
        this.accountInternationOption1.value = otherBankValue[0][Object.keys(otherBankValue[0])[0]];
      }
      if (otherBankValue[0] && otherBankValue[0].type == 'other') {
        this.accountInternationOption1.type = this.accountInternationalOptions.find((item) => item.value === 'other');
        this.accountInternationOption1Type = this.accountInternationOption1.type;
        this.accountInternationOption1.param = Object.keys(otherBankValue[0])[0];
        this.accountInternationOption1.value = otherBankValue[0][Object.keys(otherBankValue[0])[0]];
      }
      if (otherBankValue[1] && !otherBankValue[1].type) {
        this.accountInternationOption2.type = this.accountInternationalOptions.find(
          (item) => item.value === Object.keys(otherBankValue[1])[0]
        );
        this.accountInternationOption2Type = this.accountInternationOption2.type;
        this.accountInternationOption2.value = otherBankValue[1][Object.keys(otherBankValue[1])[0]];
      }
      if (otherBankValue[1] && otherBankValue[1].type === 'other') {
        this.accountInternationOption2.type = this.accountInternationalOptions.find((item) => item.value === 'other');
        this.accountInternationOption2Type = this.accountInternationOption2.type;
        this.accountInternationOption2.param = Object.keys(otherBankValue[1])[0];
        this.accountInternationOption2.value = otherBankValue[1][Object.keys(otherBankValue[1])[0]];
      }
      if (otherBankValue[2] && !otherBankValue[2].type) {
        this.accountInternationOption3.type = this.accountInternationalOptions.find(
          (item) => item.value === Object.keys(otherBankValue[2])[0]
        );
        this.accountInternationOption3Type = this.accountInternationOption3.type;
        this.accountInternationOption3.value = otherBankValue[2][Object.keys(otherBankValue[2])[0]];
      }
      if (otherBankValue[2] && otherBankValue[2].type === 'other') {
        this.accountInternationOption3.type = this.accountInternationalOptions.find((item) => item.value === 'other');
        this.accountInternationOption3Type = this.accountInternationOption3.type;
        this.accountInternationOption3.param = Object.keys(otherBankValue[2])[0];
        this.accountInternationOption3.value = otherBankValue[2][Object.keys(otherBankValue[2])[0]];
      }
    },

    async clearOthersBankConfirm() {
      this.affiliateData.other_value = null;
      this.affiliateData.other_type = null;
      this.affiliateData.aba = null;
      this.affiliateData.bic = null;
      this.affiliateData.iban = null;
      this.affiliateData.swift = null;
      this.affiliateData.routing_number = null;
    },

    async clearBrasilBankConfirm() {
      this.bankData.name = '';
      this.bankData.code = '';
      this.affiliateData.bank_name = '';
      this.affiliateData.bank_agency = '';
      this.affiliateData.bank_account_number = '';
      this.affiliateData.bank_account_number_digit = '';
    },

    async onEdit() {
      this.loading = true;
      try {
        delete this.affiliateData.id;
        delete this.affiliateData.affiliate_id;
        
        if (this.affiliateData.bank_country === 'Brasil') await this.clearOthersBankConfirm();
        else await this.clearBrasilBankConfirm();

        this.affiliateData.phone_number = this.affiliateData.phone_number.replace(/\D/g, '');

        if (this.bankData.name == null || this.bankData.name == '') {
          this.clearBrasilBankConfirm();
          this.affiliateData.bank_name = null;
        } else this.affiliateData.bank_name = `${this.bankData.code} - ${this.bankData.name}`;

        if (this.affiliateData.bank_country !== 'Brasil') await this.setOthersBankConfirm();

        const { status } = await this.editAffiliateDataPayment(this.paymentId, this.affiliateData);

        if (status === 200) this.successNotify('Afiliado Atualizado com sucesso');
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.loading = false;
      }
    },

    async onCreate() {
      this.loading = true;
      try {
        delete this.affiliateData.id;
        delete this.affiliateData.affiliate_id;
        
        if (this.affiliateData.bank_country === 'Brasil') await this.clearOthersBankConfirm();
        else await this.clearBrasilBankConfirm();

        this.affiliateData.phone_number = this.affiliateData.phone_number.replace(/\D/g, '');

        if (this.bankData.name != '') this.affiliateData.bank_name = `${this.bankData.code} - ${this.bankData.name}`;

        this.affiliateData.affiliate_id = this.id;
        if (this.affiliateData.bank_country !== 'Brasil') await this.setOthersBankConfirm();
        
        const { status } = await this.createAffiliateDataPayment(this.affiliateData);

        if (status === 200) {
          this.havePaymentData = true;
          this.successNotify('Afiliado Atualizado com sucesso');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.loading = false;
      }
    }
  },

  created() {
    this.getAffiliateById(this.id).catch(error => {
      console.error('Failed to load affiliate data:', error);
      this.errorNotify('Falha ao carregar dados do afiliado');
    });
  }
};
</script>